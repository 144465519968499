<template>
  <div class="swiper swiper-container">
    <div class="swiper-wrapper">
      <a class="swiper-slide" :href="onLine" target="_blank"><img src="/static/img/banner-20250326.png" /></a>
      <a class="swiper-slide" :href="aAll" target="_blank"><img src="/static/img/banner_20241025.png" /></a>
    </div>
    <!-- 如果需要分页器 -->
    <div class="swiper-pagination"></div>
  </div>
</template>
<script lang="ts">
import { onLine, pc_origin } from "@/data";
declare let Swiper: any;
export default {
  data() {
    return { onLine }
  },
  computed: {
    aAll: () => {
      return pc_origin + "/solution-all.html"
    }
  },
  mounted() {
    new Swiper('.swiper-container', {
      autoplay: {
        delay: 5000,//1秒切换一次
      },
      loop: true, // 循环模式选项
      // 如果需要分页器
      pagination: {
        el: '.swiper-pagination',
      },
    })
  }
}
</script>
<style lang="less" scoped>
.swiper {
  // height: 332px;
  width: 100%;
}

.swiper-slide img {
  width: 100%;
  display: block;
  pointer-events: none;
}
</style>
